import React from "react"
import { graphql } from "gatsby"

import styled from "styled-components"
import BackgroundImage from 'gatsby-background-image'

import SEO from "../components/seo"
import ContentCard from "../components/ContentCard"
import { rhythm } from "../utils/typography"
// import { FaArrowRight } from 'react-icons/fa'
// import Layout from "../components/layout"
// import SiteHeader from "../components/site-header"
// import HomepageSection from "../components/homepage-section"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    // const siteTitle = data.site.siteMetadata.title
    const sections = data.allContentJson.nodes
    // const banner = 
    
    
    // return (
    //   <Layout location={this.props.location} title={siteTitle}>
    //     <SEO title="All posts" />
    //   </Layout>
    // )
    
    const breakpoint_1 = '560px'
    const breakpoint_2 = '730px'
    const breakpoint_3 = '850px'
    const breakpoint_4 = '1040px'
    
    
    const ContentContainer = styled.div`
    @media (min-width: ${breakpoint_4}){
      width: 1040px;
      margin: 0 auto;
    }
    
    `
    
    const Header = styled(BackgroundImage)`
    
    background-size: cover;
    height: ${rhythm(17/2)};
    position: relative;      
    @media (min-width: ${breakpoint_1}){
      height: ${rhythm(19/2)};
    }
    @media (min-width: ${breakpoint_2}){
      height: ${rhythm(23/2)};
    }
    @media (min-width: ${breakpoint_3}){
      height: ${rhythm(25/2)};
    }
    @media (min-width: ${breakpoint_4}){
      height: ${rhythm(30/2)};
    }
    `
    
    const Title = styled.div`
    background-color: white;
    border-bottom-left-radius: 10px;
    float: right;
    padding: ${rhythm(1/4)};
    
    h2 {
      text-align: right;
      margin-bottom: 0px;
      font-weight: 400;
    }
    `
    
    const ContentSection = styled.div`
    padding: 0 ${rhythm(1/2)};
    border-bottom: 1px solid #374C5A;
    margin-top: ${rhythm(1/2)};
    
    h2 {
      background-color: #374C5A;
      text-transform: uppercase;
      color: white;
      font-weight: 400;
      margin-left: ${rhythm(-1/2)};
      padding-left: ${rhythm(1/2)};
      padding-top: ${rhythm(1/4)};
      height: 50px;
      min-width: 414px;
      clip-path: polygon(0 0, 210px 0, 150px ${rhythm(5/4)}, 0px ${rhythm(6/4)});
      margin-bottom: 0px
    }
    `
    
    const ContentInnerContainer = styled.div`
    display: flex;
    flex-wrap:  wrap;
    justify-content: space-between;
    `
    
    const ContentIntro = styled.div`
    
    width: 100%;
    
    @media (min-width: ${breakpoint_3}){
      width: 15%;
    }
    @media (min-width: ${breakpoint_4}){
      width: 30%;
    }
    
    `
    
    
    
    // const CTA = styled.div`
    //   text-align: right;
    
    // `
    
    
    
    return (
      <>
      <SEO title="Laurie Young" />
      
      <ContentContainer>
      
      <Header
          Tag="div"
          fluid={data.banner.childImageSharp.fluid}
        >
      <Title><h2>Wildfalcon</h2></Title>
      </Header>
      
      <ContentSection>
      <h2>Meet</h2>
      <p>I’m Laurie Young. Below you can find out more about me and my interests. 
      I’m generally curious about learning, technology, wildlife and photography.</p>
      {/* <CTA>Find out more <FaArrowRight/></CTA> */}
      </ContentSection>
      
      {sections.map((section, index) => {
        return (
          <ContentSection key={index}>
            <h2>{section.title}</h2>
            <ContentInnerContainer>
              <ContentIntro>{section.description}</ContentIntro>
              {section.content.filter(
                  (contentItem)=>{return contentItem.featured === "true"}
                ).map((contentItem, index) => {
                return (
                  <ContentCard key={index}
                    href={contentItem.href}
                    title={contentItem.title}
                    description={contentItem.description}
                    image_src={contentItem.image_src}
                    type={contentItem.type}
                  />
                )
              })}
            </ContentInnerContainer>
          </ContentSection>
        )
      })
    }
      
      
      </ContentContainer>
      </>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    banner: file(relativePath: { eq: "laurie-banner.jpg" }){
      childImageSharp {
        fluid(maxWidth: 5000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }    
    allContentJson(
        sort: {order: ASC, fields: order}
      ) {
      nodes {
        title
        content {
          title
          description
          href
          image_src {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          type
          featured
        }
        description
      }
    }
  }
`
