import React from "react"
import { breakpoint_2 } from "../shared"
// import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { rhythm } from "../utils/typography"


class ContentCard extends React.Component {
  render() {
    
    const CardDiv = styled.div`
      background-color: #D7DBDE;
      position: relative;
      margin-bottom: ${rhythm(1)};
      
      @media (min-width: ${breakpoint_2}){
        max-width: ${rhythm(13)};
      }
      
      
      a{
        text-decoration: none;
      }
      
      h3 {
        text-align: center;
        padding-top: ${rhythm(1)};
        color: black;
        
      }
      
      p {
        text-align: center;
        margin: 0 ${rhythm(1)};
        color: black;
      }
      
      img{
        clip-path: polygon(0 30%, 100% 0, 100% 100%, 0% 100%);
        margin-bottom: 0;
        display: block;
      }
      
      .card-type{
        position: absolute;
        background-color: #A31621;
        bottom: 0px;
        right: 0px;
        margin: 0px;
        width: 150px;
        height: 40px;
        clip-path: polygon(0 100%, 20% 30%, 100% 0, 100% 100%, 0% 100%);
        
        span{
          position: absolute;
          bottom: 0;
          right: 0;
          color: white;
          margin-right: ${rhythm(1/4)};
        }
      }    
    `
    
   
    
    
  return(
    <CardDiv>
      <a href={this.props.href}>
        <div className="card-top">
          <h3>{this.props.title}</h3>
          <p>{this.props.description}</p>
        </div>
        <Img fluid={this.props.image_src.childImageSharp.fluid} alt=""/>
        <div className="card-type"><span>{this.props.type}</span></div>
      </a>
    </CardDiv>
    )
  }
}

export default ContentCard